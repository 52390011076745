@import "system/mixin";
@import "system/variables";
@import "system/reset";

.login {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;
    padding: 100px 15px 40px;
    max-width: 100%;
    // height: 100vh;
    background: url(/images/hero.jpg) no-repeat top center;
    background-size: cover;
    background-attachment: fixed;

    .header {
        &__inner {
            display: flex;
            align-items: center;
        }

        &__left {
            flex-basis: 130px;
            ;
        }

        &__logo {
            &-text {
                font-size: 14px;
            }
        }

        @include respond-to(small) {
            .header__menu {
                height: auto;
                padding: 0;
            }

            .menu {
                display: block;
                position: static;
                height: auto;
                overflow: auto;
                background: transparent;
                left: 0;
                top: 0;
                opacity: 1;
                z-index: 99;
                width: 500px;
                width: auto;
                max-width: none;
                -webkit-transition: all .6s cubic-bezier(.23, 1, .32, 1);
                transition: all .6s cubic-bezier(.23, 1, .32, 1);
                -webkit-transform: translateX(-100%);
                transform: translateX(0);
            }

            .menu__items {
                height: auto;
                display: flex;
            }

            .menu__btns {
                margin: 0;
                flex-direction: row;
            }

            .menu__btn {
                margin: 0;
                margin-right: 10px;
                border: 1px solid #fff;
                border-radius: 4px;
                font-family: "Open Sans", sans-serif;
                color: #fff;
                font-size: 10px;
                font-weight: 700;
                padding: 6px 10px 4px;
                letter-spacing: .6px;
                text-transform: uppercase;
                -webkit-transition: background .5s ease, color .5s ease;
                transition: background .5s ease, color .5s ease;
                width: auto;
                min-width: 0;
            }
        }
    }

    &__content {
        max-width: 670px;
        width: 100%;
    }
&-inform{
    padding: 20px 15px; 
    text-align: center;
    &-row{
        margin-bottom: 10px;
    }
}
    &__form {

        &-wrap {
            max-width: 400px;
            margin: auto;
            border: solid 1px $brand;
            border-radius: 4px;
            margin-bottom: 50px;
        }

        &-header {
            background: rgba(0, 0, 0, 0.6);
            padding: 20px 15px 20px;
            color: #fff;
            text-align: center;
        }

        &-title {
            font-size: 26px;
            letter-spacing: 1.2px;
        }

        &-subtitle {
            display: block;
            font-size: 14px;
            letter-spacing: 1px;
        }

        &-body {
            background: rgba(255, 255, 255, .9);

            padding: 40px 20px 20px;
        }

        &-group {
            display: flex;
            justify-content: space-between;

            @include respond-to(xs) {
                flex-wrap: wrap;
            }

            &.col-2 {
                .form-group {
                    margin-right: 5px;

                    &:nth-child(2) {
                        flex-basis: 50%;
                    }

                    &:last-child {
                        flex-basis: 50%;
                        margin-right: 0;
                    }
                }
            }

            &.col-3 {
                .form-group {
                    margin-right: 5px;
                    flex-basis: 33%;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }

        .form-group {
            margin-bottom: 12px;
            zoom: 1;
            display: flex;
            flex-wrap: wrap;

            textarea {
                min-height: 90px;
            }
        }

        .form-control {
            width: 100%;
            padding: .375rem .75rem;
            line-height: 1.5;
            background-color: #fff;
            background-clip: padding-box;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            border-radius: 0;
            border: solid 1px $brand;
            box-shadow: none;
            font-size: 14px;
            color: #343434;
            display: flex;
            align-items: center;
            height: 38px;

            &-wrapper {
                flex-basis: 100%;
                height: 38px;
                position: relative;

                .fa {
                    width: 38px;
                    height: 38px;
                    line-height: 40px;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    color: rgba(145, 159, 169, 0.7);
                }

                &.form-control-icon-left {
                    .form-control {
                        padding-left: 36px;
                    }

                    .fa {
                        left: 0;
                    }
                }

                &.form-control-icon-right {
                    .form-control {
                        padding-right: 36px;
                    }

                    .fa {
                        right: 0;
                    }
                }
            }
        }

        &-reset {
            font-size: 14px;
            text-align: right;

            a {

                color: $brand;

                &:hover {
                    color: #000;
                }
            }
        }

        .help-block {
            color: #fa424a;
            font-style: normal;
            font-size: 0.8rem;
            text-align: center;
        }

        .checkbox {
            margin: 0 auto;
            position: relative;
            text-align: center;
            width: 100%;  
            justify-content: center;
            input {
                position: absolute;
                visibility: hidden;
                &+label {
                    font-size: 14px;
                    z-index: 2;
                    padding: 0 0 0 24px; 
                    position: relative;
                    display: inline-block; 
                    min-height: 18px;
                    line-height: 18px;
                    cursor: pointer;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border: solid 1px $brand;
                        width: 16px;
                        height: 16px;
                        background: #fff;
                        border-radius: 2px;
                    }
                    &:after{
                        content: ''; 
                        left: 0;  
                        width: 16px;
                        height: 16px;   
                        vertical-align: middle; 
                        top: -0.15em; 
                        text-align: center;
                        top: 0;
                        font-size: 11px;
                        line-height: 16px;
                        position: relative;
                        display: inline-block;
                        
                    }
                }
                &:checked{
                    & + label:after{
                        content: "";
                        background: $brand;
                        position: absolute;
                        display: block;
                        top: 3px;
                        left: 3px;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        .input[type="checkbox"],
        input[type="radio"] {
            box-sizing: border-box;
            padding: 0;
        }

        &-btns {
            display: flex;
            justify-content: center;
            margin: 30px auto 10px;

            .btn {
                margin: 0 10px;
            }
        }

        &-link {
            font-size: 14px;
            text-align: center;
            display: block;
            flex-basis: 100%;
            color: $brand;
            text-decoration: underline;
            margin-bottom: 15px;
        }
    }
}